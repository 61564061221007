import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store";
import Login from "@/views/account/Login.vue";
import ResetPassword from "@/views/account/ResetPassword.vue";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/clients/Auth.module";
import UserModule from "@/store/clients/User.module";

const Auth = getModule(AuthModule, Store);
const User = getModule(UserModule, Store);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login",
    },
    component: Login,
  },
  {
    path: "/Account/ResetPassword",
    name: "ResetPassword",
    meta: {
      title: "Reset Password",
    },
    component: ResetPassword,
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/VesselOverview",
    name: "VesselOverview",
    meta: {
      title: "Vessel Overview",
    },
    component: () => import("../views/VesselOverview.vue"),
  },
  {
    path: "/Help",
    component: () => import("../views/help/HelpPage.vue"),
    children: [
      { path: "", redirect: { name: "VersionHistory" } },
      {
        path: "VersionHistory",
        name: "VersionHistory",
        meta: { title: "Version History | Help" },
        component: () => import("../views/help/VersionHistory.vue"),
      },
      {
        path: "GettingStarted",
        meta: { title: "Getting Started | Help" },
        component: () => import("../views/help/GettingStarted.vue"),
      },
      {
        path: "CII",
        meta: { title: "CII | Help" },
        component: () => import("../views/help/CII.vue"),
      },
      {
        path: "Basic",
        meta: { title: "Basic | Help" },
        component: () => import("../views/help/Basic.vue"),
      },
      {
        path: "Diagnostics",
        meta: { title: "Diagnostics | Help" },
        component: () => import("../views/help/Diagnostics.vue"),
      },
      {
        path: "Fouling",
        meta: { title: "Fouling | Help" },
        component: () => import("../views/help/Fouling.vue"),
      },
      {
        path: "Compare",
        meta: { title: "Compare | Help" },
        component: () => import("../views/help/Compare.vue"),
      },
      {
        path: "CharterParty",
        meta: { title: "Charter Party | Help" },
        component: () => import("../views/help/CharterParty.vue"),
      },
      {
        path: "Notifications",
        meta: { title: "Notifications | Help" },
        component: () => import("../views/help/Notifications.vue"),
      },
      {
        path: "EmissionReport",
        meta: { title: "Emission Report | Help" },
        component: () => import("../views/help/EmissionReport.vue"),
      },
      {
        path: "FAQ",
        meta: { title: "FAQ | Help" },
        component: () => import("../views/help/FAQ.vue"),
      },
    ],
  },
  {
    path: "/Vessel/:vesselId/:vesselName",
    component: () => import("../views/vessel/Vessel.vue"),
    children: [
      { path: "", redirect: { name: "Info" } },
      { path: "Info", name: "Info", meta: { subTitle: "Info", title: "Info", parent: "Vessel" }, component: () => import("../views/vessel/VesselInfo.vue") },
      { path: "Notes", name: "Notes", meta: { subTitle: "Notes", title: "Notes", parent: "Vessel" }, component: () => import("../views/vessel/VesselNotes.vue") },
      { path: "VesselFiles", name: "VesselFiles", meta: { subTitle: "Documents", title: "Documents", parent: "Vessel" }, component: () => import("../views/vessel/VesselFiles.vue") },
      { path: "Report", name: "Report", meta: { subTitle: "Reports", title: "Report", parent: "Vessel" }, component: () => import("../views/vessel/Report.vue") },
      { path: "Shapoli", name: "Shapoli", meta: { subTitle: "EEXI ShaPoLi", title: "EEXI ShaPoLi", parent: "Vessel" }, component: () => import("../views/vessel/Shapoli.vue") },
      { path: "LoggingHistory", name: "LoggingHistory", meta: { subTitle: "Logging History", title: "Logging History", parent: "Vessel" }, component: () => import("../views/vessel/LoggingHistory.vue") },
      { path: "LongTrend", name: "LongTrend", meta: { subTitle: "Long Trend", title: "Long Trend", parent: "Vessel" }, component: () => import("../views/vessel/LongTrend.vue") },
      { path: "Diagnostics", name: "Diagnostics", meta: { subTitle: "Diagnostics", title: "Diagnostics", parent: "Vessel" }, component: () => import("../views/vessel/Diagnostics/index.vue") },
      { path: "Fouling", name: "Fouling", meta: { subTitle: "Fouling", title: "Fouling", parent: "Vessel" }, component: () => import("../views/vessel/Fouling.vue") },
      { path: "Fouling/Diagnostics", name: "FoulingDiagnostics", meta: { subTitle: "FoulingDiagnostics", title: "FoulingDiagnostics", parent: "Vessel" }, component: () => import("../views/vessel/FoulingDiagnostics.vue") },
      { path: "EmissionReport", name: "EmissionReport", meta: { subTitle: "Emission Report", title: "Emission Report", parent: "Vessel" }, component: () => import("../views/vessel/EmissionReport.vue") },
      { path: "CharterParty", name: "CharterParty", meta: { subTitle: "Charter Party", title: "Charter Party", parent: "Vessel" }, component: () => import("../views/vessel/CharterParty.vue") },
      { path: "DataAnalysis", name: "DataAnalysis", meta: { subTitle: "Data Analysis", title: "Data Analysis", parent: "Vessel" }, component: () => import("../views/vessel/DataAnalysis.vue") },
    ],
  },
  {
    path: "/Users",
    name: "Users",
    meta: {
      title: "Users",
    },
    component: () => import("../views/profile/Users.vue"),
  },
  {
    path: "/MyProfile",
    name: "MyProfile",
    meta: {
      title: "Profile",
    },
    component: () => import("../views/profile/MyProfile.vue"),
  },
  {
    path: "/Company",
    name: "company",
    meta: {
      title: "Company",
    },
    component: () => import("../views/company/CompanyManager.vue"),
  },
  {
    path: "/DataQuality/",
    component: () => import("../views/dataQuality/DataQuality.vue"),
    children: [
      { path: "", redirect: { name: "DataAvailability" } },
      {
        path: "FleetOverview",
        name: "FleetOverview",
        meta: {
          subTitle: "FleetOverview",
          title: "FleetOverview",
          parent: "DataQuality",
        },
        component: () => import("../views/dataQuality/FleetOverview.vue"),
      },
      {
        path: "DataAvailability",
        name: "DataAvailability",
        meta: {
          subTitle: "DataAvailability",
          title: "DataAvailability",
          parent: "DataQuality",
        },
        component: () => import("../views/dataQuality/DataAvailability.vue"),
      },
      {
        path: "DataOutliers",
        name: "DataOutliers",
        meta: {
          subTitle: "DataOutliers",
          title: "DataOutliers",
          parent: "DataQuality",
        },
        component: () => import("../views/dataQuality/DataOutliers.vue"),
      },
      {
        path: "SpeedLog",
        name: "SpeedLog",
        meta: {
          subTitle: "SpeedLog",
          title: "SpeedLog",
          parent: "DataQuality",
        },
        component: () => import("../views/dataQuality/SpeedLog.vue"),
      },
    ],
  },
  {
    path: "/VesselAdministration",
    name: "VesselAdministration",
    meta: {
      title: "Vessel Administration",
    },
    component: () => import("../views/vesseladmin/VesselAdministration.vue"),
  },
  {
    path: "/ReferenceCurves/PowerSpeed",
    name: "Power - Speed",
    meta: {
      title: "Reference Curves | Power - Speed",
    },
    component: () => import("../views/referencecurves/PowerSpeed.vue"),
  },
  {
    path: "/ReferenceCurves/SFOC",
    name: "SFOC - Power",
    meta: {
      title: "Reference Curves | SFOC - Power",
    },
    component: () => import("../views/referencecurves/SFOC.vue"),
  },
  {
    path: "/VesselGroups",
    name: "VesselGroups",
    meta: {
      title: "Vessel Groups",
    },
    component: () => import("../views/vesseladmin/VesselGroups.vue"),
  },
  {
    path: "/VesselAccessControl",
    name: "VesselAccessControl",
    meta: {
      title: "Vessel Access Control",
    },
    component: () => import("../views/vesseladmin/VesselAccessControl.vue"),
  },
  {
    path: "/ImportStatus",
    name: "ImportStatus",
    meta: {
      title: "Import Status",
    },
    component: () => import("../views/importstatus/ImportStatus.vue"),
  },
  {
    path: "/Taxonomy",
    name: "Taxonomy",
    meta: {
      title: "Taxonomy",
    },
    component: () => import("../views/Taxonomy.vue"),
  },
  {
    path: "/Announcement",
    name: "Announcement",
    meta: {
      title: "Announcement",
    },
    component: () => import("../views/Announcement/AnnouncementTableView.vue"),
  },
  {
    path: "/MyNotifications",
    name: "MyNotifications",
    meta: {
      title: "Notifications",
    },
    component: () => import("../views/MyNotifications/index.vue"),
  },
  {
    path: "/MyNotifications/AddNotification",
    name: "AddNotification",
    meta: {
      title: "Add Notification",
    },
    component: () => import("../views/MyNotifications/AddNotification.vue"),
  },
  {
    path: "/About",
    name: "About",
    meta: {
      title: "About",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/Kognifai",
    name: "Kognifai",
    meta: {
      title: "Kognifai",
    },
    component: () => import("../views/kognifai/DataAnalysis.vue"),
    beforeEnter(to, from, next) {
      if (User.isKognifai) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "*",
    name: "NotFound",
    meta: {
      title: "Not Found",
    },
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/Account/ResetPassword"];
  const authRequired = !publicPages.includes(to.path);

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !Auth.isLoggedIn) {
    return next("/login");
    //make sure user is valid and exists
  } else if (authRequired && User.userExpired) {
    User.getUser()
      .then(() => {
        if (User.isKognifai && to.name !== "Kognifai") {
          return next("/Kognifai");
        } else {
          return next();
        }
      })
      .catch(() => { });
  } else if (User.isKognifai && to.name !== "Kognifai") {
    return next("/Kognifai");
  } else {
    return next();
  }
});

router.afterEach((to, from) => {
  if (to.meta?.parent === "Vessel") {
    document.title = to.meta.title + " | " + to.params.vesselName + " | Danelec Fleet Insights";
  } else {
    document.title = to.meta?.title + " | Danelec Fleet Insights";
  }
});

export default router;
